import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './ui/products/products.component';
import { ProductEditComponent } from './ui/product-edit/product-edit.component';
import { HomeComponent } from './ui/home/home.component';
import { AuthGuard } from './core/auth.guard';
import { SlidersComponent } from './ui/sliders/sliders.component';
import { SliderEditComponent } from './ui/slider-edit/slider-edit.component';
import { NewProductComponent } from './ui/new-product/new-product.component';
// import { ReviewsComponent } from './ui/reviews/reviews.component';
// import { UploadComponent } from './ui/upload/upload.component';
// import { DumpComponent } from './ui/dump/dump.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
  { path: 'products/new', component: NewProductComponent, canActivate: [AuthGuard] },
  { path: 'products/edit/:productID', component: ProductEditComponent, canActivate: [AuthGuard] },
  { path: 'countries', component: SlidersComponent, canActivate: [AuthGuard] },
  { path: 'slider/edit/:cc', component: SliderEditComponent, canActivate: [AuthGuard] },
  // { path: 'reviews', component: ReviewsComponent, canActivate: [AuthGuard] },
  // { path: 'upload-reviews', component: UploadComponent, canActivate: [AuthGuard] },
  // { path: 'sku-dump', component: DumpComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

interface Slide {
  title: string,
  link: string,
  style: string,
  order: number,
  img?: string,
}

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss']
})
export class SlidersComponent implements OnInit {
  countries$: Observable<any[]>;

  constructor(
    private db: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit() {
    this.countries$ = this.db.collection('countries').valueChanges();
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Product } from '../../core/product';
import { tap, finalize } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  productID: string;
  product: Product;
  productRef: AngularFirestoreDocument<Product>;
  productForm: FormGroup;
  productCopy: Product;

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private loadingBar: LoadingBarService,
  ) {
    this.buildForm();

  }

  ngOnInit() {
  }

  buildForm() {
    this.productForm=this.fb.group({
      productID: null,

      as_item:  false,
      en_item:  false,
      featured: false,
      pack: null,
      care_pack: null,
      patch_page: null,
      patch_product: null,
      patch_sku: null,
      full_variant: null,
      otg_variant: null,
      grid_product: null,
      individual: null,

      bv:           0,
      full_retail:  null,
      price:        0,
      qv:           0,
      wp:           0,

      lcc: [[]],
      cc: null,
      name: '',
      pack_items: [null],
      pack_skus: [null],
      tmp_item: null,
      tmp_sku: null,
      photo_url: '',
      photo_file: null,
      // systems?: string[],
      otg_productID: null,
      full_productID: null,
      lab_test_url: null,
      lab_file: null,
      text_desc: '',
      tags_map: [{}],
      health_benefits: [null],
      benefit: null,
      product_vid: null,
      a_ingredients: [{}],
      ingredient: null,
      ingred_desc: null,
    })
  }

  addPackItem() {
    var tmp_items: string[] = this.productForm.get('pack_items').value
    if (tmp_items) {} else { tmp_items = [] }
    var tmp_item: string = this.productForm.get('tmp_item').value
    if (tmp_item) {
      tmp_items.push(tmp_item)
      this.productForm.get('pack_items').setValue(tmp_items)
    } else {
      console.log('No item added')
      return
    }
    this.productForm.get('tmp_item').setValue(null)
  }

  removePackItem(i: number) {
    var tmp_items: string[] = this.productForm.get('pack_items').value
    tmp_items.splice(i,1)
    this.productForm.get('pack_items').setValue(tmp_items)
  }

  addPackSku() {
    var pack_skus: string[] = this.productForm.get('pack_skus').value
    if (pack_skus) {} else { pack_skus = [] }
    var sku: string = this.productForm.get('tmp_sku').value
    if (sku) {
      pack_skus.push(sku)
      this.productForm.get('pack_skus').setValue(pack_skus)
    } else {
      console.log('No SKU added')
      return
    }
    this.productForm.get('tmp_sku').setValue(null)
  }

  removePackSku(i: number) {
    var pack_skus: string[] = this.productForm.get('pack_skus').value
    pack_skus.splice(i,1)
    this.productForm.get('pack_skus').setValue(pack_skus)
  }

  addHealthBen() {
    var benefits: string[] = this.productForm.get('health_benefits').value
    if (benefits) {} else { benefits = [] }
    var benefit: string = this.productForm.get('benefit').value
    if (benefit) {
      benefits.push(benefit)
      this.productForm.get('health_benefits').setValue(benefits)
    } else {
      console.log('No health benefit added')
      return
    }
    this.productForm.get('benefit').setValue(null)
  }

  removeHealthBen(i: number) {
    var benefits: string[] = this.productForm.get('health_benefits').value
    benefits.splice(i,1)
    this.productForm.get('health_benefits').setValue(benefits)
  }

  addCC() {
    var lcc: string[] = this.productForm.get('lcc').value
    var cc: string = this.productForm.get('cc').value
    if (cc) {
      lcc.push(cc)
      this.productForm.get('lcc').setValue(lcc)
    } else {
      console.log('No country added')
      return
    }
    this.productForm.get('cc').setValue(null)
  }

  removeCC(i: number) {
    var lcc: string[] = this.productForm.get('lcc').value
    lcc.splice(i,1)
    this.productForm.get('lcc').setValue(lcc)
  }

  addIngredient() {
    const name = this.productForm.get('ingredient').value
    const desc = this.productForm.get('ingred_desc').value
    if (name == null || desc == null) {
      return console.log('Mission Ingredient or description')
    }
    var a_ing=this.productForm.get('a_ingredients').value
    a_ing[name]=desc
    // this.productForm.get('a_ingredients').setValue('a_ing')
    this.productForm.get('ingredient').setValue(null)
    this.productForm.get('ingred_desc').setValue(null)
  }

  removeIngredient(name: string) {
    console.log('Deleting: '+name)
    var a_ing=this.productForm.get('a_ingredients').value
    delete a_ing[name]
    // this.productForm.get('a_ingredients').setValue('a_ing')
    console.log(this.productForm.get('a_ingredients').value)
  }


  toggleTag(tag: string) {
    var tmp_tags: {[tag: string]: boolean} = this.productForm.get('tags_map').value
    if (tmp_tags) {
    } else {
      tmp_tags = {}
    }
    if (tmp_tags[tag]) {
      tmp_tags[tag] = !tmp_tags[tag]
    } else {
      tmp_tags[tag] = true
    }
    this.productForm.get('tags_map').setValue(tmp_tags)
  }

  differ() {
    var diff = {}
    var data = this.productForm.value
    Object.entries(data).forEach(entry => {
      var key = entry[0]
      var value = entry[1]

      if ((value == null) || (value == [])) {
        // console.log(key)
        // console.log(value)
      } else {
        diff[key]=value
      }
    })

    return diff
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0)
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }
    this.loadingBar.start()
    console.log('Starting Upload')
    const path = `uploaded/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file)
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('Done Uploading')
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          console.log(url)
          this.productForm.get('photo_url').setValue(url)
          this.productForm.get('photo_file').setValue(null)
          this.loadingBar.complete()
        });
      })
    ).subscribe()
  }

  startLabUpload(event: FileList) {
    const file = event.item(0)
    if (file.type.split('/')[0] !== 'image' && file.type.split('/')[1] !== 'pdf') {
      console.log('failed')
      return
    }
    this.loadingBar.start()
    console.log('Starting Upload')
    const path = `lab_results/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file)
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('Done Uploading')
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          console.log(url)
          this.productForm.get('lab_test_url').setValue(url)
          this.productForm.get('lab_file').setValue(null)
          this.loadingBar.complete()
        });
      })
    ).subscribe()
  }

  submitHandler() {
    console.log(this.differ())
    return

    // this.productRef=this.db.doc(`products/${this.productForm.get('productID').value}`);
    // this.loadingBar.start()
    // const diff = this.differ()
    // console.log(diff)
    // this.productRef.update(diff)
    //   .then( () => {
    //     console.log('updated')
    //     this.loadingBar.complete()
    //   }).catch(error => {
    //     console.log(error)
    //   })
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userForm: FormGroup;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.auth.user$.subscribe((user) => {
      if(user){
        console.log('yes user')
      } else {
        console.log('no user')
      }
    })
  }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    this.userForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email,
      ]],
      'password': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        // Validators.minLength(6),
        // Validators.maxLength(25),
        Validators.required,
      ]],
      'name': ['',],
    });
  }

  login() {
    console.log("logging in!!!");
    this.auth.emailLogin(this.userForm.value['email'], this.userForm.value['password']).then(() => {
      console.log('I\'m in!');
    }).catch(error => {
      console.log(error)});
  }

  logout() {
    this.auth.logOut()
    .then(() => {
      console.log('logged out')
      this.router.navigateByUrl('/')
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products$: Observable<any>

  constructor(
    private db: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit() {
    this.products$ = this.db.collection('products').valueChanges();
  }

}

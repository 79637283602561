import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { tap, finalize, take } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';

interface Slide {
  title: string,
  link: string,
  linkHTML: string,
  style: string,
  order: number,
  img?: string,
  slideID: string,
  dropashadow?: boolean,
  img_style?: string,
}

@Component({
  selector: 'app-slider-edit',
  templateUrl: './slider-edit.component.html',
  styleUrls: ['./slider-edit.component.scss']
})
export class SliderEditComponent implements OnInit {
  slidesForms: FormGroup;
  cc: string;
  slideIDs: string[] = [];
  show: boolean = false;
  bannerURL: string = null;
  removedIDs: string[] = [];

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private loadingBar: LoadingBarService,
  ) {
    this.route.params.subscribe((params) => {
      if (params['cc']) {
        this.slidesForms = this.fb.group({
          slides: this.fb.array([]),
          banner: null,
        })
        this.cc = params['cc']
        this.setCC(params['cc'])
      }
    })
  }

  ngOnInit() {
  }

  get sForms() {
    return this.slidesForms.get('slides') as FormArray
  }

  setCC(cc: string) {
    const slidesCollection = this.db.collection<Slide>(`countries/${cc}/slides`);
    slidesCollection.valueChanges().pipe(take(1)).subscribe(slides => {
      this.loadingBar.start()
      if (slides) {
        for (let slide of slides) {
          if (this.slideIDs.includes(slide.slideID)) {
            continue;
          }
          const slide_form = this.fb.group({
            title: slide.title,
            link: slide.link,
            linkHTML: slide.linkHTML,
            style: slide.style,
            img: slide.img || null,
            order: slide.order,
            slideID: slide.slideID,
            dropashadow: slide.dropashadow||null,
            img_style: slide.img_style||null,
          })
          this.sForms.push(slide_form);
          this.slideIDs.push(slide.slideID);
        }
      }
      this.loadingBar.stop()
      this.show = true;
    })
  }

  startBannerUpload(event: FileList) {
    const file = event.item(0)
    if (file.type.split('/')[0] !== 'image' && file.type.split('/')[1] !== 'pdf') {
      console.log('failed')
      return
    }
    this.loadingBar.start()
    console.log('Starting Upload')
    const path = `banners/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file)
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('Done Uploading')
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          console.log(url)
          this.bannerURL=url
          this.slidesForms.get('banner').setValue(null)
          this.loadingBar.complete()
        });
      })
    ).subscribe()
  }

  removeSlide(i: number) {
    const id = this.sForms.at(+i).value.slideID;
    this.sForms.removeAt(i)
    this.removedIDs.push(id)
  }

  deleteSlides() {
    for (let id of this.removedIDs) {
      if (this.slideIDs.includes(id)) {
        const slideRef: AngularFirestoreDocument<Slide>=this.db.doc(`countries/${this.cc}/slides/${id}`);
        slideRef.delete().then(() => {
          console.log('slide deleted')
        }).catch(error => console.log(error))
      } else {
        console.log(id +' is not in slideIDs')
      }
    }
  }

  addSlide() {
    const slide = this.fb.group({
      title: '',
      link: '',
      linkHTML: '',
      style: '',
      img: null,
      order: 0,
      slideID: this.db.createId(),
      dropashadow: false,
      img_style: '',
    })
    this.sForms.push(slide);
  }

  submitHandler() {
    for (let i in this.sForms.value) {
      this.loadingBar.start()
      const slide = this.sForms.at(+i).value;
      const slideRef: AngularFirestoreDocument<Slide>=this.db.doc(`countries/${this.cc}/slides/${slide.slideID}`);
      const data: Slide = {
        title: slide.title,
        link: slide.link,
        linkHTML: slide.linkHTML,
        style: slide.style,
        img: slide.img || null,
        order: slide.order,
        slideID: slide.slideID,
        dropashadow: slide.dropashadow||null,
        img_style: slide.img_style||null,
      }
      slideRef.set(data,{merge: true}).then(() => {
        this.loadingBar.complete()
      }).catch(error => {
        console.log(error);
        this.loadingBar.complete()
      })
    }
    this.deleteSlides()
  }

}

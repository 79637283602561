import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProductsComponent } from './products/products.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { HomeComponent } from './home/home.component';
import { SlidersComponent } from './sliders/sliders.component';
import { SliderEditComponent } from './slider-edit/slider-edit.component';
import { NewProductComponent } from './new-product/new-product.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewEditComponent } from './review-edit/review-edit.component';
import { UploadComponent } from './upload/upload.component';
import { DumpComponent } from './dump/dump.component';

@NgModule({
  declarations: [UserProfileComponent, ProductsComponent, ProductEditComponent, HomeComponent, SlidersComponent, SliderEditComponent, NewProductComponent, ReviewsComponent, ReviewEditComponent, UploadComponent, DumpComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TooltipModule.forRoot(),
  ],
  exports: [UserProfileComponent]
})
export class UiModule { }

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from './user';
import { switchMap, startWith, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
      tap(user => {
        if (user) {
          localStorage.setItem('user', JSON.stringify(user))
        }
      }),
      startWith(JSON.parse(localStorage.getItem('user')))
    );
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        console.log(credential.user);
      })
      .catch(error => console.log(error));
  }

  logOut(): Promise<void> {
    localStorage.removeItem("user");
    return this.afAuth.auth.signOut()
  }

  isAdmin(user: User): boolean {
    if (user.admin) {
      return (user.admin)
    } else { return false }
  }

}

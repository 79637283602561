import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  reviews: any[];

  constructor(
    private db: AngularFirestore,
  ) {
    var revRef = this.db.collection<any>('reviews', ref => ref.where('approved','==',false))
    revRef.stateChanges().subscribe(rvws => {
      let tmp_reviews = rvws.map(rvw => {
        let tmp_doc = rvw.payload.doc.data()
        tmp_doc.id = rvw.payload.doc.id
        tmp_doc.text = tmp_doc.raw_review
        // rvw.payload.doc.data().text = rvw.payload.doc.data().raw_review
        // console.log(rvw.payload.doc.id);
        // rvw.payload.doc.data().id = rvw.payload.doc.id
        // console.log(tmp_doc.id);
        return tmp_doc
      })
      // console.log(tmp_reviews);

      this.reviews = tmp_reviews

      // console.log(this.reviews);
    })
  }

  ngOnInit() {
    // this.reviews$=this.db.collection('reviews', ref => ref.where('approved','==',false)).valueChanges()
  }

  approve_review(review_) {
    review_.approved = true;
    review_.text = review_.raw_review
    // console.log(review)
    this.db.doc('reviews/'+review_.id).update(review_).then(res => {
      console.log('Updated review');
    }).catch(err => {
      console.log(err)
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Product } from '../../core/product';
import { tap, finalize } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  productID: string;
  product: Product;
  productRef: AngularFirestoreDocument<Product>;
  productForm: FormGroup;
  productCopy: Product;
  newProdForm: FormGroup;

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private loadingBar: LoadingBarService,

  ) {
    this.route.params.subscribe((params) => {
      if (params['productID']) {
        this.productForm=this.fb.group({})
        this.productID = params['productID']
        this.setProduct(params['productID'])
      }
    })
  }

  ngOnInit() {
  }

  get pxcs() {
    return this.productForm.get('pxc') as FormArray
  }

  setProduct(productID: string) {
    this.productRef = this.db.doc<Product>(`products/${productID}`)
    this.productRef.valueChanges().subscribe((product) => {
      if (product) {
        this.product=product
        this.buildForm()
        this.productCopy=(JSON.parse(JSON.stringify(product)))
      }
    })
  }

  buildForm() {
    this.productForm=this.fb.group({
      as_item:  this.product.as_item,
      en_item:  this.product.en_item,
      featured: this.product.featured,
      pack:    this.product.pack || null,
      care_pack: this.product.care_pack || null,
      patch_page: this.product.patch_page || null,
      patch_product: this.product.patch_product || null,
      patch_sku: this.product.patch_sku || null,
      full_variant: this.product.full_variant || null,
      otg_variant: this.product.otg_variant || null,
      grid_product: this.product.grid_product || null,
      individual: this.product.individual || null,

      bv:           this.product.bv,
      full_retail:  this.product.full_retail || null,
      price:        this.product.price,
      qv:           this.product.qv,
      wp:           this.product.wp,

      lcc: [this.product.lcc],
      cc: null,
      name: this.product.name,
      pack_items: [this.product.pack_items || null],
      pack_skus: [this.product.pack_skus || null],
      tmp_item: null,
      tmp_sku: null,
      photo_url: this.product.photo_url,
      photo_file: null,
      productID: this.product.productID,
      // systems?: string[],
      otg_productID: this.product.otg_productID || null,
      full_productID: this.product.full_productID || null,
      lab_test_url: this.product.lab_test_url || null,
      lab_file: null,
      text_desc: this.product.text_desc,
      tags_map: [this.product.tags_map || {}],
      health_benefits: [this.product.health_benefits || null],
      benefit: null,
      product_vid: this.product.product_vid || null,
      a_ingredients: [this.product.a_ingredients || {}],
      ingredient: null,
      ingred_desc: null,

      pxc: this.fb.array([]),
    })

    this.newProdForm=this.fb.group({
      productID: "",
      name: "",
    })

    if (this.product.pxc) {
      Object.entries(this.product.pxc).forEach((thing) => {
        const pbc = this.fb.group({
          cc: thing[0],
          price: thing[1]
        })
        this.pxcs.push(pbc)
      })
    }
    this.checkPXCdiff()
  }

  checkPXCdiff() {
    for (let cc of this.product.lcc) {
      console.log(cc)
      if (this.pxcs.value.findIndex(function(item) {
        return item.cc == cc;
      })==-1) {
        const pbc = this.fb.group({
          cc: cc,
          price: this.product.price,
        })
        this.pxcs.push(pbc)
      }
      // console.log(lloc)
    }
  }

  addPackItem() {
    var tmp_items: string[] = this.productForm.get('pack_items').value
    if (tmp_items) {} else { tmp_items = [] }
    var tmp_item: string = this.productForm.get('tmp_item').value
    if (tmp_item) {
      tmp_items.push(tmp_item)
      this.productForm.get('pack_items').setValue(tmp_items)
    } else {
      console.log('No item added')
      return
    }
    this.productForm.get('tmp_item').setValue(null)
  }

  removePackItem(i: number) {
    var tmp_items: string[] = this.productForm.get('pack_items').value
    tmp_items.splice(i,1)
    this.productForm.get('pack_items').setValue(tmp_items)
  }

  addPackSku() {
    var pack_skus: string[] = this.productForm.get('pack_skus').value
    if (pack_skus) {} else { pack_skus = [] }
    var sku: string = this.productForm.get('tmp_sku').value
    if (sku) {
      pack_skus.push(sku)
      this.productForm.get('pack_skus').setValue(pack_skus)
    } else {
      console.log('No SKU added')
      return
    }
    this.productForm.get('tmp_sku').setValue(null)
  }

  removePackSku(i: number) {
    var pack_skus: string[] = this.productForm.get('pack_skus').value
    pack_skus.splice(i,1)
    this.productForm.get('pack_skus').setValue(pack_skus)
  }

  addHealthBen() {
    var benefits: string[] = this.productForm.get('health_benefits').value
    if (benefits) {} else { benefits = [] }
    var benefit: string = this.productForm.get('benefit').value
    if (benefit) {
      benefits.push(benefit)
      this.productForm.get('health_benefits').setValue(benefits)
    } else {
      console.log('No health benefit added')
      return
    }
    this.productForm.get('benefit').setValue(null)
  }

  removeHealthBen(i: number) {
    var benefits: string[] = this.productForm.get('health_benefits').value
    benefits.splice(i,1)
    this.productForm.get('health_benefits').setValue(benefits)
  }

  addCC() {
    var lcc: string[] = this.productForm.get('lcc').value
    var cc: string = this.productForm.get('cc').value
    if (cc) {
      lcc.push(cc)
      this.productForm.get('lcc').setValue(lcc)
      this.pxcs.push(this.fb.group({
        cc: cc,
        price: this.product.price,
      }))
    } else {
      console.log('No country added')
      return
    }
    this.productForm.get('cc').setValue(null)
  }

  removeCC(i: number) {
    var lcc: string[] = this.productForm.get('lcc').value
    const cc=lcc.splice(i,1)[0]
    this.productForm.get('lcc').setValue(lcc)
    i=this.pxcs.value.findIndex(function(item) {
      return item.cc == cc;
    })
    this.pxcs.removeAt(i)
  }

  addIngredient() {
    const name = this.productForm.get('ingredient').value
    const desc = this.productForm.get('ingred_desc').value
    if (name == null || desc == null) {
      return console.log('Mission Ingredient or description')
    }
    var a_ing=this.productForm.get('a_ingredients').value
    a_ing[name]=desc
    // this.productForm.get('a_ingredients').setValue('a_ing')
    this.productForm.get('ingredient').setValue(null)
    this.productForm.get('ingred_desc').setValue(null)
  }

  removeIngredient(name: string) {
    console.log('Deleting: '+name)
    var a_ing=this.productForm.get('a_ingredients').value
    delete a_ing[name]
    // this.productForm.get('a_ingredients').setValue('a_ing')
    console.log(this.productForm.get('a_ingredients').value)
  }


  toggleTag(tag: string) {
    var tmp_tags: {[tag: string]: boolean} = this.productForm.get('tags_map').value
    if (tmp_tags) {
    } else {
      tmp_tags = {}
    }
    if (tmp_tags[tag]) {
      tmp_tags[tag] = !tmp_tags[tag]
    } else {
      tmp_tags[tag] = true
    }
    this.productForm.get('tags_map').setValue(tmp_tags)
  }

  differ() {
    var diff = {}
    var data = this.productForm.value
    Object.entries(data).forEach(entry => {
      var key = entry[0]
      var value: any = entry[1]
      if (key != 'pxc') {
        if (value == this.productCopy[key]) {
        } else {
          diff[key] = value
        }
      } else {
        var pxc = {}
        for (let item of value) {
          pxc[item.cc]=item.price
        }
        diff['pxc']=pxc
      }
    })

    return diff
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0)
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }
    this.loadingBar.start()
    console.log('Starting Upload')
    const path = `uploaded/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file)
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('Done Uploading')
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          console.log(url)
          this.productForm.get('photo_url').setValue(url)
          this.productForm.get('photo_file').setValue(null)
          this.loadingBar.complete()
        });
      })
    ).subscribe()
  }

  startLabUpload(event: FileList) {
    const file = event.item(0)
    if (file.type.split('/')[0] !== 'image' && file.type.split('/')[1] !== 'pdf') {
      console.log('failed')
      return
    }
    this.loadingBar.start()
    console.log('Starting Upload')
    const path = `lab_results/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file)
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('Done Uploading')
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          console.log(url)
          this.productForm.get('lab_test_url').setValue(url)
          this.productForm.get('lab_file').setValue(null)
          this.loadingBar.complete()
        });
      })
    ).subscribe()
  }

  submitHandler() {
    this.loadingBar.start()
    const diff = this.differ()
    console.log(diff)
    this.productRef.update(diff)
      .then( () => {
        console.log('updated')
        this.loadingBar.complete()
      }).catch(error => {
        console.log(error)
      })
  }

  copyProduct() {
    this.loadingBar.start()
    console.log('start');
    var old_prod = this.db.doc<any>(`products/${this.productID}`).valueChanges()
    old_prod.subscribe((op)=> {
      if (op) {
        const np = this.newProdForm.value
        op.productID = np['productID']
        op.name = np['name']
        var new_prod = this.db.doc<any>(`products/${np['productID']}`)
        new_prod.set(op, {merge: true}).then(() => {
          this.loadingBar.stop()
        })
      }
    })
  }

}

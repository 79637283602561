import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  raw_json = new FormControl('')
  count = 0;


  raw_reviews: any[] = [];
  reviews: any[];

  prod_skus: string[] = [];
  prod_ratings: number[][] = [];
  prod_average: number[] = [];

  constructor(
    private db: AngularFirestore,
  ) {
  }

  ngOnInit() {
  }

  async doSomething() {
    console.log('something');
    for (let rev of JSON.parse(this.raw_json.value)) {
      let data: any = {
      raw_review: rev['Review Comments'] || null,
      text: rev['Review Comments'] || null,
      headline: rev['Review Headline'] || null,
      email: rev['Review Email'] || null,
      name: rev['Review Nickname'] || null,
      rating: Number(rev['Review Rating']) || null,
      stars:  Number(rev['Review Rating']) || null,
      powerRev: true,
      approved: false,
      sku: rev['Page ID'] || null,
      date: new Date(rev['Created Date'])
      }
      if (this.prod_skus.includes(data.sku)) {
        let i = this.prod_skus.indexOf(data.sku);
        this.prod_ratings[i].push(data.rating);
      } else {
        this.prod_skus.push(data.sku)
        this.prod_ratings.push([data.rating]);
      }

      this.raw_reviews.push(data);
    }

    this.prod_ratings.forEach((ratings, i) => {
      let avg = ratings.reduce((a,b) => a + b, 0) / ratings.length;
      this.prod_average.push(avg);
    })

    // await this.raw_reviews.forEach((rev) => {
    //   this.db.collection('reviews').add(rev)
    //     .then(() => {
    //       this.count += 1;
    //     }).catch(err => {
    //       console.log(err)
    //       console.log(this.count);
    //     })
    // })
    // console.log('Done...')
    // console.log(this.count);

  }


}
